import React from 'react';
import { colorGray200 } from '@amzn/meridian-tokens/base/color';
import Column from '@amzn/meridian/column';
import Row from '@amzn/meridian/row';
import Text from '@amzn/meridian/text';
import Divider from '@amzn/meridian/divider';


const footerStyle = {
  borderTop: `1px solid ${colorGray200}`,
};

const Footer = () => {
  return (  
    <div style={footerStyle}>
       <Column spacing="small">
      <Divider size='small' />
      <Row width='100%' alignmentHorizontal='center'>
      <Text><a href="https://wiki.labcollab.net/confluence/display/Doppler/ISP+Revoke+Purchase+Tool+SOP" target="_blank">ISP Revoke Purchase Tool SOP</a></Text>
      </Row>
      <Row width='100%' alignmentHorizontal='center' height={40}>
          <Text><a href="https://t.corp.amazon.com/create/templates/20dae0f9-2575-425b-92aa-82e88b0f4c45" target="_blank">Report issue/Feature request</a></Text>
          </Row>

          </Column>
      <Column
      
        alignmentHorizontal='center'
        backgroundColor='alternateSecondary'
        spacingInset='medium large'
      >
        <Row spacing="small" alignmentHorizontal='center' width={950} maxWidth='100%'>
          <Text>Confidential, for Amazon use only.</Text>
        </Row>
      </Column>
    </div>

    
  );

};

export default Footer;